import { Injectable } from '@angular/core'
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'

@Injectable({ providedIn: 'root' })
export class DialogHelper {

	constructor(
		private dialogService: DialogService,
	) {}

	public open(component: any, config: DynamicDialogConfig, data: object): DynamicDialogRef {
		let defaultConfig: DynamicDialogConfig = {
			width: '80%',
			baseZIndex: 10000,
			maximizable: true,
			data: data,
		}

		return this.dialogService.open(component, { ...defaultConfig, ...config })
	}
}
