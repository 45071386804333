import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Filter } from '@core/interfaces/filter'
import { TriStateCheckboxChangeEvent, TriStateCheckboxModule } from 'primeng/tristatecheckbox'
import { EnumSelectComponent } from '@app/components/form/enum-select/enum-select.component'
import { UserSelectComponent } from '@app/components/form/entity-selects/user-select/user-select.component'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'toggle-filter',
  standalone: true,
  imports: [
    TriStateCheckboxModule,
    EnumSelectComponent,
    UserSelectComponent,
    FormsModule
  ],
  templateUrl: './toggle-filter.component.html'
})
export class ToggleFilterComponent {
  @Input() filter!: Filter
  @Output() onToggleFilterChange: EventEmitter<any> = new EventEmitter<any>()

  protected value: any

  onToggleFilterChanged(event: TriStateCheckboxChangeEvent) {
    this.onToggleFilterChange.emit(event.value)
  }
}
