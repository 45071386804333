<p-contextMenu #cm [model]="contextMenuActions"></p-contextMenu>

<p-table
	dataKey="id"
	[value]="data"
	[columns]="columns"
	[lazy]="true"
	[lazyLoadOnInit]="false"
	[paginator]="!hidePagination"
	[rows]="!hidePagination ? rowPerPage : undefined"
	[rowsPerPageOptions]="!hidePagination ? [10, 30, 50, 100, 250, 500] : []"
	[totalRecords]="totalRecords"
	[rowTrackBy]="trackByFn"
	[(contextMenuSelection)]="selectedEntity"
	[contextMenu]="contextMenuActions.length > 0 ? cm : null"
	[rowHover]="true"
	[scrollable]="true"
	[showCurrentPageReport]="true"
	[sortField]="defaultSortField"
	[sortOrder]="defaultSortOrder"
	[selectionPageOnly]="selectable"
	[tableStyle]="{'min-width': '50rem'}"
	[(selection)]="selectedRows"
	(onLazyLoad)="loadData($event)"
	(onContextMenuSelect)="selectedEntityChange.emit(selectedEntity)"
	(selectionChange)="onSelectionChange()"
	scrollHeight="flex"
	currentPageReportTemplate="{first} - {last} von {totalRecords}"
>
	<ng-template pTemplate="caption">
		<div class="flex align-items-center justify-content-between">
			<span class="font-bold text-900" style="font-size:1.5rem">{{ title }}</span>

			<span class="p-input-icon-left">
				<ng-container *ngIf="addUrl.length > 0 && this.canAdd > 0 && this.authService.hasPermission(this.canAdd)">
					<tb-btn label="Hinzufügen" icon="pi pi-plus" (onClick)="this.addItem()" />
				</ng-container>

				<ng-content select="[addButton]"></ng-content>
			</span>
		</div>
	</ng-template>

	<ng-template pTemplate="header">
		<tr *ngIf="filters.length > 0 || selectable">
			<th [colSpan]="(selectable || expandable) ? columns.length + 1 : columns.length">
				<tb-btn *ngIf="filters.length > 0" [secondary]="true" (click)="op.toggle($event)" label="Filter" icon="pi pi-chevron-down" class="mr-2" />
				<p-overlayPanel #op>
					<div class="flex flex-column gap-2 w-20rem">
						<ng-container *ngFor="let filter of filters">
							<div>
								<ng-container *ngIf="filter.type === FilterType.User">
									<tb-user-filter
										[filter]="filter"
										[label]="filter.label"
										(onUserFilterChange)="filterUser($event, filter.name)"
									/>
								</ng-container>

								<ng-container *ngIf="filter.type === FilterType.Toggle">
									<toggle-filter
										[filter]="filter"
										(onToggleFilterChange)="filterToggle($event, filter.name)"
									/>
								</ng-container>

								<ng-container *ngIf="filter.type === FilterType.EnumSelect">
									<enum-filter
										[filter]="filter"
										(onEnumFilterChange)="filterEnum($event, filter.name)"
									/>
								</ng-container>

								<ng-container *ngIf="filter.type === FilterType.DateRange">
									<date-range-filter
										[filter]="filter"
										(onDateRangeFilterSelect)="filterRangeDate($event)"
									/>
								</ng-container>

								<ng-container *ngIf="filter.type === FilterType.Exist">
									<exist-filter
										[filter]="filter"
										(onExistFilterChange)="filterExist($event, filter)"
									/>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</p-overlayPanel>

				<tb-btn *ngIf="selectable" [secondary]="true" [disabled]="selectedRows.length <= 0" [label]="'Massenverarbeitung (' + selectedRows.length + ')'" (onClick)="selectedRows.length > 0 ? menu.toggle($event) : ''" icon="pi pi-chevron-down" />
				<p-menu #menu [model]="bulkActions" [popup]="true" appendTo="body"></p-menu>
			</th>
		</tr>

		<tr>
			<ng-container *ngIf="selectable">
				<th style="width: 4rem">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</th>
			</ng-container>

			<ng-container *ngIf="expandable">
				<th></th>
			</ng-container>

			<ng-container *ngFor="let header of columns">
				<ng-container *ngIf="isSortable(header); else unsortable">
					<th [pSortableColumn]="header.field.toString()">
						{{ header.label }}
						<p-sortIcon [field]="header.field.toString()"></p-sortIcon>
					</th>
				</ng-container>

				<ng-template #unsortable>
					<th
						[style]="header.width ? { 'width': header.width } : {}"
						[pTooltip]="header.infoTooltip"
						tooltipPosition="top"
					>
						{{ header.label }}
						<i class="pi pi-info-circle" style="font-size:1rem" *ngIf="(header.infoTooltip ?? '').length > 0"></i>
					</th>
				</ng-template>
			</ng-container>
		</tr>
	</ng-template>

	<ng-template pTemplate="emptymessage">
		<span class="ml-2">Keine Daten vorhanden</span>
	</ng-template>

	<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded">
		<tr (contextmenu)="onContextMenu($event, rowData, rowIndex)" [style.background]="getRowBackgroundStyle(highlights, rowData)" [ngClass]="{ 'height-expandable': expandable && !hasExpandableContent(rowData) }">

			<td *ngIf="expandable">
				<ng-container *ngIf="showExpandableButton(rowData)">
					<button type="button" pButton pRipple [pRowToggler]="rowData" (click)="onExpanded($event, rowData)" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
				</ng-container>
			</td>

			<td *ngIf="selectable">
				<p-tableCheckbox [value]="rowData"></p-tableCheckbox>
			</td>

			<td *ngFor="let col of columns" [style]="col.width ? { 'width': col.width } : {}" [ngClass]="col.noWrap ? 'nowrap' : ''">
				<ng-container *ngIf="hasModifiableCellContent(col); else elseBlock">
					<ng-container
						[ngTemplateOutlet]="getModifiableCellContent(col).templateRef"
						[ngTemplateOutletContext]="{ row: rowData, col: col, field: col.field, m: getModifiableCellContent(col).templateRef }"
					/>
				</ng-container>

				<ng-template #elseBlock>
					<ng-container *ngIf="col.format === ColumnFormat.Items else elseIf">
						<tb-items-overlay [items]="tableHelper.formatTableEntry(col, rowData)" />
					</ng-container>

					<ng-template #elseIf>
						<ng-container *ngIf="col.format === ColumnFormat.Address else elseIf0">
							<tb-address-overlay [address]="tableHelper.formatTableEntry(col, rowData)" />
						</ng-container>

						<ng-template #elseIf0>
							<ng-container *ngIf="col.format === ColumnFormat.String else elseIf1">
								<tb-string-field [value]="tableHelper.formatTableEntry(col, rowData)" [maxLength]="col.maxLength ? col.maxLength : 30" [tooltip]="col.tooltip !== undefined ? col.tooltip : false" />
							</ng-container>

							<ng-template #elseIf1>
								{{ tableHelper.formatTableEntry(col, rowData) }}
							</ng-template>
						</ng-template>
					</ng-template>
				</ng-template>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="rowexpansion" let-rowData>
		<tr>
			<ng-container
				[ngTemplateOutlet]="expandableRowContent.templateRef"
				[ngTemplateOutletContext]="{ row: rowData }"
			/>
		</tr>
	</ng-template>
</p-table>
