<div class="input-group">
	<tb-control-label [label]="label" />

  <p-calendar
    [(ngModel)]="rangeDates"
    [showIcon]="true"
    [showClear]="rangeDates.length > 0"
    [showWeek]="true"
    [showButtonBar]="true"
    [readonlyInput]="true"
    [numberOfMonths]="2"
    selectionMode="range"
    dateFormat="dd.mm.yy"
    (onSelect)="onDatesSelected(rangeDates)"
    (onClear)="onCleared()"
    (onClearClick)="onCleared()"
  >
    <ng-template pTemplate="header">
      <div class="flex py-1">
        <p-button label="Letzte Woche" [text]="true" [raised]="true" class="flex-auto flex align-items-center justify-content-center" (onClick)="onDateRangePicked(1, 'week')"></p-button>
        <p-button label="Letzter Monat" [text]="true" [raised]="true" class="flex-auto flex align-items-center justify-content-center" (onClick)="onDateRangePicked(1, 'month')"></p-button>
        <p-button label="Letztes Jahr" [text]="true" [raised]="true" class="flex-auto flex align-items-center justify-content-center" (onClick)="onDateRangePicked(1, 'year')"></p-button>
        <p-button label="Aktueller Monat" [text]="true" [raised]="true" class="flex-auto flex align-items-center justify-content-center" (onClick)="onDateRangePicked(0, 'month')"></p-button>
        <p-button label="Aktuelles Jahr" [text]="true" [raised]="true" class="flex-auto flex align-items-center justify-content-center" (onClick)="onDateRangePicked(0, 'year')"></p-button>
      </div>
      <hr>
    </ng-template>
  </p-calendar>
</div>
