import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { OverlayPanelModule } from 'primeng/overlaypanel'

@Component({
	selector: 'tb-address-overlay',
	standalone: true,
	templateUrl: './address-overlay.component.html',
	imports: [
		OverlayPanelModule
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressOverlayComponent implements OnChanges {

	@Input({ required: true }) address: any

	protected formattedAddress: string = ''

	constructor(
		private cd: ChangeDetectorRef,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.processAddress()
		this.cd.markForCheck()
	}

	processAddress(): void {
		let result: string = ''

		if (typeof this.address === 'object') {
			if (this.address.company && this.address.company.length > 0) result = this.address.company
			if (this.address.companyAddition && this.address.companyAddition.length > 0) result += '<br>' + this.address.companyAddition

			if (this.address.lastName && this.address.lastName.length > 0) {
				if (result.length > 0)
					result += '<br>'

				if (this.address.firstName && this.address.firstName.length > 0) {
					result += this.address.firstName + ' '
				}

				result += this.address.lastName
			}

			if (this.address.gln && this.address.gln.length > 0) result += '<br>GLN: ' + this.address.gln
			if (this.address.street && this.address.street.length > 0) result += '<br>' + this.address.street
			if (this.address.postcode && this.address.postcode.length > 0 && this.address.city.length > 0) result += '<br>' + this.address.postcode + ' ' + this.address.city
			if (this.address.country && this.address.country?.name.length > 0) result += '<br>' + this.address.country.name
		}

		this.formattedAddress = result
	}

}
