import { Injectable } from '@angular/core'
import { ApiService } from '@core/services/api/api.service'
import { DataService } from '@core/interfaces/data-service'
import { TableLazyLoadEvent } from 'primeng/table'
import { Observable, throwError } from 'rxjs'
import { User } from '@core/models/shared/user.model'

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService implements DataService {
  protected override endpoint: string = 'users' || 'permissions'

  public getCollection(event: TableLazyLoadEvent): Observable<User[]> {
    return this._getCollection<User>(event)
  }

  public getCollectionSelect(filter: string, event: TableLazyLoadEvent | null = null): Observable<User[]> {
    return this._getCollectionSelect<User>(filter, event, true)
  }

  public getItem(id: number): Observable<User> {
    return this._getItem<User>(id)
  }

  public getTotalItems(): number {
    return this._getTotalItems()
  }

  public create(entity: User): Observable<User> {
    if (entity.id && entity.id > 0)
      return this.update(entity)

    return this._post(entity)
  }

  public update(entity: User): Observable<User> {
    if (entity.id && entity.id > 0)
      return this._patch(entity.id, entity)

    return throwError(() => new Error('Entity has invalid id'))
  }

  public delete(entity: User): Observable<any> {
    if (entity.id && entity.id > 0)
      return this._delete(entity.id)

    return throwError(() => new Error('Entity has invalid id'))
  }

  public getPermissionsTree(): Observable<any> {
    return this._post({}, 'permissions')
  }

}
