import { Component, Input, ViewEncapsulation } from '@angular/core'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { NgStyle } from '@angular/common'

@Component({
  selector: 'tb-progress-spinner',
  standalone: true,
  template: `
    <p-progressSpinner
      styleClass="p-progress-spinner-svg"
      strokeWidth="4"
      animationDuration="1s"
      [ngStyle]="{ width: '3rem', height: '3rem' }"
    />
  `,
  styles: `
    .p-progress-spinner {
      width: 4rem;
      height: 4rem;
    }
  
    .p-progress-spinner-circle {
      stroke: var(--primary-color) !important;
    }
  `,
  imports: [
    ProgressSpinnerModule,
    NgStyle
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressSpinnerComponent {

  //@Input() disabled: boolean = false

}
