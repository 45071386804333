import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class LocalSearchService {

  public subject$: BehaviorSubject<string> = new BehaviorSubject<string>('')

  public search(value: string): void {
   this.subject$.next(value)
  }

}
