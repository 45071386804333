export enum FilterType {
  EnumSelect,
  DateRange,
  Toggle,
  User,
  Exist,
}

export interface Filter {
  type: FilterType
  name: string
  label: string
  enumName?: string
  multiple?: boolean
  initialValue?: any
  nullable?: boolean
  disable?: boolean
  clearable?: boolean
  options?: Array<object>
}
