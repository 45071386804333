<ng-container *ngIf="filter.multiple else singleSelect">
  <tb-enum-multiselect
    [enumName]="filter.enumName ? filter.enumName : ''"
		[label]="filter.label"
		[disabled]="filter.disable || false"
		[showClear]="filter.clearable ? filter.clearable : false"
		[style]="{'min-width':'270px'}"
		[(ngModel)]="value"
		(onValueChange)="onEnumFilterChanged($event)"
		[hideErrorMessages]="true"
  />
</ng-container>

<ng-template #singleSelect>
  <tb-enum-select
    [enumName]="filter.enumName ? filter.enumName : ''"
    [label]="filter.label"
		[disabled]="filter.disable || false"
    [showClear]="filter.clearable ? filter.clearable : false"
		[style]="{'min-width':'160px'}"
		[(ngModel)]="value"
    (onValueChange)="onEnumFilterChanged($event)"
		[hideErrorMessages]="true"
		appendTo=""
  />
</ng-template>
