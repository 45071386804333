import { Injectable } from '@angular/core'
import { ApiService } from '@core/services/api/api.service'
import { DataService } from '@core/interfaces/data-service'
import { TableLazyLoadEvent } from 'primeng/table'
import { Observable } from 'rxjs'
import { StorageHistory } from '@core/models/logistics/storage-history.model'

@Injectable({ providedIn: 'root' })
export class StorageHistoryService extends ApiService implements DataService {

  protected override endpoint: string = 'logistics/storage-histories'

  public getCollection(event: TableLazyLoadEvent): Observable<StorageHistory[]> {
    return this._getCollection<StorageHistory>(event)
  }

  public getCollectionFlat(event: TableLazyLoadEvent, disableLoader: boolean = false): Observable<StorageHistory[]> {
    return this._getCollection<StorageHistory>(event, disableLoader)
  }

  public getItem(id: number): Observable<StorageHistory> {
    return this._getItem<StorageHistory>(id)
  }

  public getTotalItems(): number {
    return this._getTotalItems()
  }

}
