import { Component, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { NgForOf, NgIf, NgStyle } from '@angular/common'
import { InputComponent } from '@app/components/form/input/input.component'
import { ButtonComponent } from '@app/components/uiux/button/button.component'
import { ArticleService } from '@core/data-services/goods/article.service'
import { MessagesModule } from 'primeng/messages'
import { Message } from 'primeng/api'
import { ProgressSpinnerComponent } from '@app/components/uiux/progress-spinner/progress-spinner.component'
import { ArticleStockDetail } from '@core/models/shared/article/article-stock-detail.model'
import { TooltipModule } from 'primeng/tooltip'
import { AccordionModule } from 'primeng/accordion'
import { TableModule } from 'primeng/table'
import { EnumFieldComponent } from '@app/components/uiux/enum-field/enum-field.component'
import { Formatter } from '@core/common/formatter'
import { StringFieldComponent } from '@app/components/uiux/string-field/string-field.component'
import { RouterLink } from '@angular/router'
import { EntityTableComponent } from '@app/components/tables/entity-table/entity-table.component'
import { Column, ColumnFormat } from '@core/interfaces/column'
import { TableHelper } from '@core/common/table-helper'
import { StorageLocationService } from '@core/data-services/logistics/storage-location.service'
import { StorageHistory } from '@core/models/logistics/storage-history.model'
import { StorageHistoryService } from '@core/data-services/logistics/storage-history.service'

@Component({
  standalone: true,
  templateUrl: './article-stock-dialog.component.html',
	imports: [
		FormsModule,
		NgIf,
		ReactiveFormsModule,
		InputComponent,
		ButtonComponent,
		MessagesModule,
		NgStyle,
		ProgressSpinnerComponent,
		TooltipModule,
		AccordionModule,
		TableModule,
		EnumFieldComponent,
		StringFieldComponent,
		RouterLink,
		EntityTableComponent,
		NgForOf
	],
})
export class ArticleStockDialogComponent implements OnInit {

  protected errorMessage: Message[] = [{ severity: 'error', summary: 'Fehler', detail: 'Daten konnten nicht geladen werden.' }]
  protected id: number = 0
  protected loading: boolean = false
  protected expanded: boolean = false
  protected data!: ArticleStockDetail
	protected storageHistoryList: StorageHistory[] = []

  constructor(
		protected tableHelper: TableHelper,
    private articleService: ArticleService,
		private storageHistoryService: StorageHistoryService,
    private ref: DynamicDialogRef,
    config: DynamicDialogConfig,
  ) {
    if (config.data.id) {
      this.id = config.data.id
    } else {
      this.id = 0
    }
  }

  ngOnInit(): void {
    this.loadData()
  }

  protected close(): void {
    this.ref.close()
  }

  private loadData(): void {
    if (this.id > 0) {
      this.loading = true

      this.articleService.getStockDetail(this.id)
        .subscribe(stockDetail => {
          this.data = stockDetail

					this.storageHistoryService.getCollectionFlat({ filters: { 'article.id': { value: this.id } }, sortField: 'createdAt', sortOrder: 2 }, true)
						.subscribe(data => {
							this.storageHistoryList = data
							this.loading = false
						})
        })
    }
  }

  protected calcDeliveryDatesString(deliveryDates: string[]): string {
    return deliveryDates.length > 0 ? deliveryDates.join('\r\n') : '-'
  }

  protected readonly Formatter = Formatter
  protected storageHistoryColumns: Column[] = [
    { label: 'Datum', field: 'createdAt', format: ColumnFormat.Date },
    { label: 'Menge', field: 'quantity', format: ColumnFormat.Integer, appendix: 'Stk.' },
    { label: 'Zustand', field: 'condition', enumName: 'Logistics\\StorageLocationItemCondition' },
    { label: 'Referenznummer', field: 'referenceNumber', format: ColumnFormat.String },
    { label: 'Typ', field: (row: any) : string => row.type === 'plus' ? 'Wareneingang' : (row.type === 'minus' ? 'Warenausgang' : 'Umlagerung') },
    { label: 'Ursprung', field: (row: any): string => (row.origin !== null ? row.origin.name + ' (' + row.origin.partner?.name + ')' : '-') },
    { label: 'Ziel', field: (row: any): string => (row.target !== null ? row.target.name + ' (' + row.target.partner?.name + ')' : '-') },
		{ field: 'note', label: 'Kommentar', tooltip: true, maxLength: 5, format: ColumnFormat.String },
  ]
}
