import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { EntitySelectComponent } from '@app/components/form/entity-select/entity-select.component'
import { UserService } from '@core/data-services/shared/user.service'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { BaseFormControlComponent } from '@app/components/base/base-form-control.component'
import { InputNumberModule } from 'primeng/inputnumber'
import { User } from '@core/models/shared/user.model'

@Component({
  selector: 'tb-user-select',
  templateUrl: 'user-select.component.html',
  standalone: true,
	imports: [
		EntitySelectComponent,
		InputNumberModule,
	],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectComponent),
      multi: true
    },
  ],
})
export class UserSelectComponent extends BaseFormControlComponent {

  @Input() override label: string = ''

  @Output() onUserSelected: EventEmitter<User|null> = new EventEmitter<User|null>()

  constructor(
    protected userService: UserService,
  ) {
    super(...BaseFormControlComponent.deps())
  }

  onSelected(data: any): void {
    this.value = (this.returnId && data?.id) ? data.id : data
    this.onUserSelected.emit(this.value)
  }

}
