import { Component, Input, OnInit } from '@angular/core'
import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'
import { EnumService } from '@core/services/api/enum.service'

@Component({
  selector: 'tb-enum-field',
  standalone: true,
  template: `{{ label }}`,
  imports: [
    ButtonModule,
    RippleModule
  ],
})
export class EnumFieldComponent implements OnInit {

  @Input({ required: true }) value!: string | number
  @Input({ required: true }) enumName!: string

  protected label: string = ''

  constructor(private enumService: EnumService) {
  }

  ngOnInit(): void {
    if (this.value && this.enumName) {
      this.label = this.enumService.getLabel(this.enumName, this.value)
    }
  }

}
