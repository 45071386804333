import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CalendarModule } from 'primeng/calendar'
import { FormsModule } from '@angular/forms'
import moment from 'moment'
import { NgIf } from '@angular/common'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'

@Component({
  selector: 'tb-date-range-picker',
  standalone: true,
  templateUrl: './date-range-picker.component.html',
	imports: [
		CalendarModule,
		FormsModule,
		NgIf,
		ControlLabelComponent
	],
})
export class DateRangePickerComponent {

  @Input({ required: true }) label: string = ''
  @Output() onDatesSelect: EventEmitter<Date[]> = new EventEmitter<Date[]>()

  protected rangeDates: Date[] = []
  protected id: string = ''

  onDatesSelected(rangeDates: Date[]) {
    this.onDatesSelect.emit(rangeDates)
  }

  onDateRangePicked(offset: number = 0, unit: moment.unitOfTime.DurationConstructor) {
    const startOfRange = moment().subtract(offset, unit).startOf(unit).toDate()
    const endOfRange = moment().subtract(offset, unit).endOf(unit).toDate()
    this.rangeDates = [startOfRange, endOfRange]
    this.onDatesSelect.emit(this.rangeDates)
  }

  onCleared() {
    this.rangeDates = []
    this.onDatesSelect.emit(this.rangeDates)
  }

}
