import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { EnumSelectComponent } from '@app/components/form/enum-select/enum-select.component'
import { Filter } from '@core/interfaces/filter'
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown'
import { NgIf } from '@angular/common'
import { EnumMultiselectComponent } from '@app/components/form/enum-multiselect/enum-multiselect.component'
import { UserSelectComponent } from '@app/components/form/entity-selects/user-select/user-select.component'
import { FormsModule } from '@angular/forms'

@Component({
  standalone: true,
  selector: 'enum-filter',
  templateUrl: './enum-filter.component.html',
  imports: [
    EnumSelectComponent,
    DropdownModule,
    NgIf,
    EnumMultiselectComponent,
    UserSelectComponent,
    FormsModule
  ],
})
export class EnumFilterComponent implements OnInit {

  @Input() filter!: Filter
  @Output() onEnumFilterChange: EventEmitter<any> = new EventEmitter<any>()

  protected value: any

  ngOnInit(): void {
    if (typeof this.filter?.initialValue !== 'undefined') {
      this.value = this.filter.initialValue
    }
  }

  onEnumFilterChanged(event: DropdownChangeEvent): void {
    this.onEnumFilterChange.emit(event)
  }

}
