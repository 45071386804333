import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Filter } from '@core/interfaces/filter'
import { DateRangePickerComponent } from '@app/components/form/date-range-picker/date-range-picker.component'

@Component({
  selector: 'date-range-filter',
  standalone: true,
  imports: [
    DateRangePickerComponent,
  ],
  templateUrl: './date-range-filter.component.html'
})
export class DateRangeFilterComponent {

  @Input() filter!: Filter
  @Output() onDateRangeFilterSelect: EventEmitter<Date[]> = new EventEmitter<Date[]>()

  protected value: any

  onDateRangeFilterSelected(rangeDates: Date[]) {
    this.onDateRangeFilterSelect.emit(rangeDates)
  }

}
