import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { EnumCase } from '@core/interfaces/enum-case'
import { EnumService } from '@core/services/api/enum.service'
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect'
import { NgIf } from '@angular/common'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'
import { BaseFormControlComponent } from '@app/components/base/base-form-control.component'
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { DropdownModule } from 'primeng/dropdown'

@Component({
  standalone: true,
  selector: 'tb-enum-multiselect',
  templateUrl: './enum-multiselect.component.html',
  imports: [
    MultiSelectModule,
    NgIf,
    ControlLabelComponent,
    DropdownModule,
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnumMultiselectComponent),
      multi: true
    },
  ],
})
export class EnumMultiselectComponent extends BaseFormControlComponent {

  @Input({ required: true }) enumName: string = ''
  @Input() showClear: boolean = false
  @Input() style: object = {}
  @Input() defaultValue: number | null = null

  @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>()

  protected enums: EnumCase[] = []

  constructor(
    private enumService: EnumService
  ) {
    super(...BaseFormControlComponent.deps())
  }

  override init(): void {
    const enums = this.enumService.getOptions(this.enumName)

    if (enums !== undefined) {
      this.enums = enums.cases
    }
  }

  onValueChanged(event: MultiSelectChangeEvent): void {
    this.onValueChange.emit(event)
    this.onChange(this.value)
  }

  onClear(): void {
    this.value = this.defaultValue
  }

}
