import { Column, ColumnFormat } from '@core/interfaces/column'
import { EnumService } from '@core/services/api/enum.service'
import { Injectable } from '@angular/core'
import { Formatter } from '@core/common/formatter'
import { Address } from '@core/models/shared/address.model'
import { MenuItem } from 'primeng/api'
import { AuthService } from '@core/services/api/auth.service'
import { ContextMenuItem } from '@core/interfaces/context-menu-item'

@Injectable({ providedIn: 'root' })
export class TableHelper {

	constructor(
		private enumService: EnumService,
		private authService: AuthService,
	) {}

	public formatTableEntry(col: Column, row: object) {

		let value: string = ''

		// set value by column field
		if (typeof col.field === 'function') {
			value = col.field(row)
		} else if (col.field.includes('.')) {
			let levels: string[] = col.field.split('.')

			if (levels.length === 1) {
				value = levels[0] || '-'
			} else if (levels.length > 1) {
				let nestedValue: any = row

				for (const key of levels) {
					nestedValue = (nestedValue as any)[key] ? (nestedValue as any)[key] : '-'
				}

				value = nestedValue || '-'
			}
		} else {
			value = (row as any)[col.field as string] as string
		}

		// format value
		if ('format' in col) {
			// enum
			if (col.format === ColumnFormat.Enum) {
				return this.enumService.getLabel(col.enumName as string, value)
			}

			// currency
			else if (col.format === ColumnFormat.Currency) {
				return Formatter.formatCurrency(value)
			}

			// percentage
			else if (col.format === ColumnFormat.Percentage) {
				return Formatter.formatPercentage(value)
			}

			// string and string with nested keys
			else if (col.format === ColumnFormat.String) {
				if (Array.isArray(value)) {
					return value.join('\r\n') || '-'
				} else if (col.field === 'shippingAddress') {
					if ('shippingAddress' in row) {
						let address = row['shippingAddress'] as Address

						return `${ address.company } ${ address.street } ${ address.postcode } ${ address.city } ${ address.country.name }` || '-'
					}
				} else {
					return value
				}
			}

			// items
			else if (col.format === ColumnFormat.Items) {
				return value
			}

			// date
			else if (col.format === ColumnFormat.Date) {

				// handle multiple dates
				if (value === null) {
					return '-'
				}

				if (typeof value === 'object' && !((value as any) instanceof Date)) {
					return (value as any).join('\n') || '-'
				}

				return Formatter.formatDate(value) || '-'
			}

			// integer
			else if (col.format === ColumnFormat.Integer) {
				return Formatter.formatIntNumber(value, col.appendix || '')
			}

			// decimal
			else if (col.format === ColumnFormat.Decimal) {
				return Formatter.formatNumber(value, col.appendix || '')
			}

			// boolean
			else if (col.format === ColumnFormat.Bool) {
				return value ? 'Ja' : 'Nein'
			}

			else {
				return value || '-'
			}
		} else {
			return value || '-'
		}
	}

	public processContextMenuActions(actions: ContextMenuItem[], currentRow: any): MenuItem[] {
		let result: MenuItem[] = []

		// loop actions and check condition if set
		for (let action of actions) {
			if (typeof action.condition === 'undefined' || (typeof action.condition === 'function' && action.condition(currentRow, this.authService))) {
				let items: MenuItem[] = []

				// process sub items if set
				if (Array.isArray(action.items) && action.items.length > 0) {
					items = this.processContextMenuActions(action.items, currentRow)
				}

				result.push({
					label: action.label,
					icon: action.icon,
					command: action.command,
					items: items,
				} as MenuItem)
			}
		}

		return result
	}

	public calcTotalQuantity(items: any) {
		let result = 0

		items.forEach(function (item: any) {
			if (item.shipmentItem) {
				result += item?.shipmentItem?.quantity || 0
			} else {
				result += item.quantity || 0
			}
		})

		return result
	}

}
