import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core'
import { Filter } from '@core/interfaces/filter'
import { TriStateCheckboxChangeEvent, TriStateCheckboxModule } from 'primeng/tristatecheckbox'
import { FormsModule } from '@angular/forms'

@Component({
	selector: 'exist-filter',
	standalone: true,
	imports: [
		TriStateCheckboxModule,
		FormsModule
	],
	templateUrl: 'exist-filter.component.html'
})
export class ExistFilterComponent implements OnChanges {
	@Input() filter!: Filter
	@Output() onExistFilterChange: EventEmitter<any> = new EventEmitter<any>()

	protected value: any

	ngOnChanges(): void {
		if (typeof this.filter?.initialValue !== 'undefined') {
			this.value = this.filter.initialValue
		}
	}

	onExistFilterChanged(event: TriStateCheckboxChangeEvent) {
		this.onExistFilterChange.emit(event.value)
	}
}