import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UserSelectComponent } from '@app/components/form/entity-selects/user-select/user-select.component'
import { Filter } from '@core/interfaces/filter'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'tb-user-filter',
  standalone: true,
  imports: [
    UserSelectComponent,
    FormsModule
  ],
  templateUrl: 'user-filter.component.html'
})
export class UserFilterComponent {

  @Input() filter!: Filter
  @Input() label: string = 'Verantwortlicher Benutzer'

  @Output() onUserFilterChange: EventEmitter<any> = new EventEmitter<any>()

  protected value: any

  onUserFilterChanged(event: any): void {
    this.onUserFilterChange.emit(event?.id)
  }
}
