import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewEncapsulation, } from '@angular/core'
import { Table, TableContextMenuSelectEvent, TableLazyLoadEvent, TableModule } from 'primeng/table'
import { ConfirmationService, FilterMatchMode, FilterMetadata, MenuItem, MenuItemCommandEvent } from 'primeng/api'
import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common'
import { InputTextModule } from 'primeng/inputtext'
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu'
import { ButtonModule } from 'primeng/button'
import { LocalSearchService } from '@core/services/search.service'
import { Entity } from '@core/models/entity'
import { Router } from '@angular/router'
import { DataService } from '@core/interfaces/data-service'
import { Subscription } from 'rxjs'
import { AvatarModule } from 'primeng/avatar'
import { EnumFilterComponent } from '@app/components/tables/entity-table/filters/enum/enum-filter.component'
import { Filter, FilterType } from '@core/interfaces/filter'
import { DateRangeFilterComponent } from '@app/components/tables/entity-table/filters/date-range/date-range-filter.component'
import { ToggleFilterComponent } from '@app/components/tables/entity-table/filters/toggle/toggle-filter.component'
import { EnumMultiselectComponent } from '@app/components/form/enum-multiselect/enum-multiselect.component'
import { Column, ColumnFormat } from '@core/interfaces/column'
import { Formatter } from '@core/common/formatter'
import { AuthService } from '@core/services/api/auth.service'
import { AccordionModule } from 'primeng/accordion'
import { BadgeModule } from 'primeng/badge'
import { StringFieldComponent } from '@app/components/uiux/string-field/string-field.component'
import { UserFilterComponent } from '@app/components/tables/entity-table/filters/user/user-filter.component'
import { TableHelper } from '@core/common/table-helper'
import { RippleModule } from 'primeng/ripple'
import { ExpandableRowDirective } from '@core/directives/expandable-row.directive'
import { Highlight } from '@core/interfaces/highlight'
import { ModifiableCellDirective } from '@core/directives/modifiable-cell.directive'
import { ContextMenuItem } from '@core/interfaces/context-menu-item'
import { ExistFilterComponent } from '@app/components/tables/entity-table/filters/exist/exist-filter.component'
import { ButtonComponent } from '@app/components/uiux/button/button.component'
import { MenuModule } from 'primeng/menu'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { ItemsOverlayComponent } from '@app/components/uiux/items-overlay/items-overlay.component'
import { AddressOverlayComponent } from '@app/components/uiux/address-overlay/address-overlay.component'
import { LoggerService } from '@core/services/logger.service'

@Component({
  standalone: true,
  selector: 'entity-table',
  templateUrl: './entity-table.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: `
    .fixed-height-row {
      height: 2.88rem !important; // For exact same height between expandable and not expandable rows
    }`,
  imports: [
    TableModule,
    NgForOf,
    InputTextModule,
    ContextMenuModule,
    ButtonModule,
    AvatarModule,
    EnumFilterComponent,
    NgIf,
    DateRangeFilterComponent,
    ToggleFilterComponent,
    EnumMultiselectComponent,
    AccordionModule,
    BadgeModule,
    StringFieldComponent,
    UserFilterComponent,
    RippleModule,
    NgTemplateOutlet,
    ExistFilterComponent,
    ButtonComponent,
    MenuModule,
    OverlayPanelModule,
    NgClass,
    ItemsOverlayComponent,
    AddressOverlayComponent,
  ],
})
export class EntityTableComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(Table) table!: Table
  @ViewChild('cm') contextMenu!: ContextMenu
  @ContentChild(ExpandableRowDirective) expandableRowContent!: ExpandableRowDirective
  @ContentChildren(ModifiableCellDirective) modifiableCellChildren!: QueryList<ModifiableCellDirective>

  @Input({ required: true }) dataService!: DataService
  @Input({ required: true }) columns: Column[] = []
  @Input() actions: ContextMenuItem[] = []
  @Input() filters: Filter[] = []
  @Input() highlights!: Highlight
  @Input() title: string = ''
  @Input() addUrl: string = ''
  @Input() editUrl: string = ''
  @Input() canAdd: number = 0
  @Input() canShow: number = 0
  @Input() canEdit: number = 0
  @Input() canDelete: number = 0
  @Input() hidePagination: boolean = false
  @Input() rowPerPage: number = 30
  @Input() selectedEntity!: Entity
  @Input() selectedRows: any[] = []
  @Input() defaultSortField!: string
  @Input() defaultSortOrder: number = 1
  @Input() expandable: boolean = false
  @Input() selectable: boolean = false
  @Input() bulkActions: ContextMenuItem[] = []
  @Input() hasExpandableContent!: (row: any) => boolean
  @Input() editableStates: number[] = []

  @Output() selectedEntityChange: EventEmitter<Entity> = new EventEmitter<Entity>()
  @Output() selectedRowsChange: EventEmitter<object[]> = new EventEmitter<object[]>()
  @Output() onLoadData: any = new EventEmitter<TableLazyLoadEvent>()
  @Output() onContextMenuSelect: EventEmitter<TableContextMenuSelectEvent> = new EventEmitter<TableContextMenuSelectEvent>()

  private modifiableCellChildrenMap: Map<string, any> = new Map<string, any>()
  protected contextMenuActions: MenuItem[] = []
  protected data: Entity[] = []
  protected searchSubscription!: Subscription
  protected lastEvent!: TableLazyLoadEvent

  constructor(
    private localSearchService: LocalSearchService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private loggerService: LoggerService,
    private cd: ChangeDetectorRef,
    protected authService: AuthService,
    protected tableHelper: TableHelper,
  )
  {}

  showExpandableButton(row: any): boolean {
    if (typeof this.hasExpandableContent === 'function')
      return this.hasExpandableContent(row)

    return false
  }

  ngOnInit(): void {
    this.subscribeSearch()

    this.setupContextMenu({})
  }

  ngOnDestroy(): void {
    this.unsubscribeSearch()
  }

  ngAfterViewInit(): void {
    this.initFilters()

    if (this.lastEvent) {
      this.loadData(this.lastEvent)
    } else {
      const searchValue: string = this.localSearchService.subject$.getValue()

      if (searchValue.length > 0) {
        this.table.filterGlobal(searchValue, FilterMatchMode.CONTAINS)
      } else {
        this.loadData(this.table.createLazyLoadMetadata())
      }
    }

    // init modifiableCellChildrenFields
    if (this.modifiableCellChildren.length > 0) {
      this.modifiableCellChildren.forEach(c => this.modifiableCellChildrenMap.set(c.field, c))
    }
  }

  subscribeSearch(): void {
    this.searchSubscription = this.localSearchService.subject$.subscribe((value: string): void => {
      if (this.table) {
        this.table.filterGlobal(value, FilterMatchMode.CONTAINS)
      }
    })
  }

  unsubscribeSearch(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe()
    }
  }

  reload(): void {
    this.selectedRowsChange.emit([])
    this.loadData(this.lastEvent || {})
  }

  loadData(event: TableLazyLoadEvent): void {
    this.dataService.getCollection(event)
      .subscribe(data => {
        this.data = data
        this.lastEvent = event
      })
  }

  addItem(): void {
    this.router.navigate([ this.addUrl ])
  }

  editItem(entity?: Entity): void {
    if (entity && entity.id && entity.id > 0) {
      this.router.navigate([ this.editUrl + entity.id ])
    }
    else {
      this.loggerService.error('EntityTable', { message: 'invalid entity id for editItem()', additionalInfos: entity })
    }
  }

  deleteItem(event: MenuItemCommandEvent, entity?: Entity): void {
    if (entity && event.originalEvent) {
      this.confirmationService.confirm({
        target: event.originalEvent.target as EventTarget,
        message: 'Möchtest du den Eintrag wirklich entfernen?',
        header: 'Entfernen?',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-text',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: 'Entfernen',
        rejectLabel: 'Abbrechen',
        accept: () => {
          if (entity) {
            this.dataService.delete?.(entity)
              .subscribe(() => {
                this.reload()
              })
          }
        },
      })
    } else {
      this.loggerService.error('EntityTable', { message: 'invalid event or entity for deleteItem()', additionalInfos: { event: event, entity: entity } })
    }
  }

  setupContextMenu(selectedRow: any): void {
    this.contextMenuActions = []

    if (this.authService.hasPermission(this.canEdit)) {
      this.contextMenuActions.push(
        { label: this.isEditable(selectedRow) ? 'Bearbeiten' : 'Anzeigen', icon: 'pi pi-fw pi-file-edit', command: () => this.editItem(selectedRow) },
      )
    } else if (this.authService.hasPermission(this.canShow)) {
      this.contextMenuActions.push(
        { label: 'Anzeigen', icon: 'pi pi-fw pi-eye', command: () => this.editItem(selectedRow) },
      )
    }

    if (this.authService.hasPermission(this.canDelete) && this.isEditable(selectedRow)) {
      this.contextMenuActions.push(
        { label: 'Entfernen', icon: 'pi pi-fw pi-trash', command: (event: MenuItemCommandEvent) => this.deleteItem(event, selectedRow) },
      )
    }
  }

  onExpanded(event: any, row: any): void {
    if (row) {
      this.selectedEntity = row
      this.selectedEntityChange.emit(this.selectedEntity)
    }
  }

  onContextMenu(event: any, row: any, rowIndex: number): void {
    this.onContextMenuSelect.emit({
      data: row,
      index: rowIndex,
      originalEvent: event
    })

    // force update changes
    this.cd.markForCheck()

    // revert contextMenuActions to initial state
    this.setupContextMenu(row)

    // process all actions passed to this component
    this.contextMenuActions = this.contextMenuActions.concat(this.tableHelper.processContextMenuActions(this.actions, row))

    if (this.contextMenuActions.length > 0) {
      // update selection
      this.selectedEntity = row
      this.selectedEntityChange.emit(this.selectedEntity)

      this.contextMenu.show(event)
    }

    event.preventDefault()
  }

  onSelectionChange(): void {
    this.selectedRowsChange.emit(this.selectedRows)
  }

  initFilters(): void {
    for (let filter of this.filters) {
      if (filter.initialValue) {
        if (filter.type === FilterType.EnumSelect) {
          this.table.filters[filter.name] = { value: filter.initialValue, matchMode: 'equal' } as FilterMetadata // set filter directly, so there is no request sent
        }
        else if (filter.type === FilterType.DateRange) {
          this.filterRangeDate((filter.initialValue as Date[]))
        }
        else if (filter.type === FilterType.User) {
          this.filterEnum({ value: filter.initialValue }, filter.name)
        }
        else if (filter.type === FilterType.Toggle) {
          this.filterToggle((filter.initialValue as boolean), filter.name)
        }
        else if (filter.type === FilterType.Exist) {
          this.filterExist((filter.initialValue as boolean), filter)
        }
      }
    }
  }

  filterRangeDate(rangeDates: Date[]) {
    if (rangeDates.length > 1) {
      if (rangeDates[1] !== null) {
        let startDate = Formatter.formatDateTime(rangeDates[0], 'start')
        let endDate = Formatter.formatDateTime(rangeDates[1], 'end')
        this.table.filter(startDate, 'createdAt[after]', 'equal')
        this.table.filter(endDate, 'createdAt[before]', 'equal')
      }
    }
    else {
      this.table.filter('', 'createdAt[after]', 'equal')
      this.table.filter('', 'createdAt[before]', 'equal')
    }
  }

  filterToggle(val: boolean | null, filterName: string) {
    if (val === null) {
      this.table.filter('', filterName, 'equal')
    }
    else {
      this.table.filter(val, filterName, 'equal')
    }
  }

  filterUser(val: number | null, filterName: string) {
    if (val === null) {
      this.table.filter('', filterName, 'equal')
    }
    else {
      this.table.filter(val, filterName, 'equal')
    }
  }

  filterEnum(obj: any, filterName: string): void {
    if (obj.hasOwnProperty('value')) {
      if (obj.value === null || obj.value.length < 1) {
        this.table.filter('', filterName, 'equal')
      }
      else {
        this.table.filter(obj.value, filterName, 'equals')
      }
    }
  }

  filterExist(val: boolean, filter: Filter): void {
    if (filter.options && filter.options.some(option => ( option as { squareBrackets?: boolean } ).squareBrackets)) {
      this.table.filter(val, 'exists[' + filter.name + ']', 'equal')
    }
    else {
      this.table.filter(val, filter.name, 'equal')
    }
  }

  isSortable(col: Column): boolean {
    return (col.sortable && typeof col.field === 'string') || false
  }

  getRowBackgroundStyle(highlights: Highlight, row: object): string {
    let bgc: string = ''

    if (highlights) {
      if (row.hasOwnProperty(highlights.field)) {
        highlights.colors.forEach(color=> {
          if (color.value === ( row as any )[highlights.field]) {
            bgc = color.colorCode
          }
        })
      }
    }

    return bgc
  }

  hasModifiableCellContent(col: Column): boolean {
    if (this.modifiableCellChildrenMap.size <= 0) return false

    return typeof col.field === 'string' && this.modifiableCellChildrenMap.has(col.field)
  }

  getModifiableCellContent(col: Column): any {
    return typeof col.field === 'string' ? this.modifiableCellChildrenMap.get(col.field) : null
  }

  isEditable(entity: any): boolean {
    if (entity && this.editableStates.length > 0) {
      return this.editableStates.includes(entity?.state || 1)
    }

    return true
  }

  protected get totalRecords() { return this.dataService.getTotalItems() }

  protected trackByFn(index: any, item: any) { return item.id }

  protected readonly FilterType = FilterType

  protected readonly ColumnFormat = ColumnFormat
}
