import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
	standalone: true,
	selector: '[modifiableCell]'
})
export class ModifiableCellDirective {

	@Input() field: string = ''

	constructor(
		public templateRef: TemplateRef<unknown>
	) {}
}
