<span class="pi pi-list cursor-pointer" (mouseover)="op.toggle($event)"></span>

<p-overlayPanel #op [style]="{ width: '800px' }">
	<ng-template pTemplate="content">
		<p-contextMenu #cm [model]="contextMenuActions" appendTo="body"></p-contextMenu>

		<p-table
			[value]="items"
			[columns]="columns"
			[paginator]="false"
			[rows]="undefined"
			[totalRecords]="items.length"
			[(contextMenuSelection)]="selectedRow"
			[contextMenu]="cm"
			[rowHover]="true"
			[scrollable]="true"
			dataKey="id"
			responsiveLayout="scroll"
			styleClass="p-datatable-sm p-datatable-gridlines"
		>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns">
						{{ col.label }}
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowData let-columns="columns">
				<tr (contextmenu)="onContextMenu($event, rowData)">
					<td *ngFor="let col of columns">
						<ng-container *ngIf="col.format === ColumnFormat.String else elseIf0">
							<tb-string-field [value]="tableHelper.formatTableEntry(col, rowData)" [maxLength]="col.maxLength ? col.maxLength : 30" [tooltip]="col.tooltip !== undefined ? col.tooltip : false" />
						</ng-container>

						<ng-template #elseIf0>
							{{ tableHelper.formatTableEntry(col, rowData) }}
						</ng-template>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-template>
</p-overlayPanel>
